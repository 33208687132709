import Counter from "../../../../common/counter";
import SectionTitle from "../../../../common/sectionTitle";
import data from "../../../../assets/data/Team/dataV2";

import TeamStyleWrapper from "./Team.style";

const Team = () => {
  const { TeamDescription1, TeamDescription2 } = data;
  return (
    <TeamStyleWrapper>
      <div className="container">
        <div className="row Team_row">
          <div className="col-lg-6">
            <div className="v5_Team_us_left_sect">
              <SectionTitle
                title="Tokenomics"
                subtitle="Tokenomics "
                className="section_title"
              />
              <div className="v5_Team_us_left_text">
                <p>{TeamDescription1}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="Team_right_item">
            <div className="counter_item">
                <h3>
                  $SKID
                </h3>
                <h6>Ticker</h6>
              </div>
              <div className="counter_item">
                <h3>
                  Success Kid
                </h3>
                <h6>Token Name</h6>
              </div>
              <div className="counter_item">
                <h3>
                  <Counter
                    end={88888888}
                    decimal="."
                    decimals={88888888 % 1 !== 0 ? "2" : "0"}
                    suffix={""}
                  />
                </h3>
                <h6>Token Supply</h6>
              </div>
              <div className="counter_item">
                <h3>
                  <Counter
                    end={100}
                    decimal="."
                    decimals={100 % 1 !== 0 ? "2" : "0"}
                    suffix={" Years"}
                  />
                </h3>
                <h6>Liquidity Lock</h6>
              </div>
              <div className="counter_item">
                <h3>
                  Renounced
                </h3>
                <h6>Contract</h6>
              </div>
              <div className="counter_item">
                <h3>
                  <Counter
                    end={0}
                    decimal="."
                    decimals={0 % 1 !== 0 ? "2" : "0"}
                    suffix={"%"}
                  />
                </h3>
                <h6>Taxes</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TeamStyleWrapper>
  );
};

export default Team;
