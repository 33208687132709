import React from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import SectionTitle from "../../../../common/sectionTitle";
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from "../../../../common/accordion/Accordion";
import { data1, data2 } from "../../../../assets/data/faqDataV2";
import FAQStyleWrapper from "./Faq.style";

const FAQ = () => {
  const renderTextWithSpecificLinks = (text) => {
    // Define specific URLs we want to hyperlink
    const specificUrls = [
      { url: "https://linktr.ee/successkid", text: "Linktree" },
      { url: "https://t.me/SuccessKidOfficialSol", text: "Telegram" },
      { url: "https://x.com/successkid_sol", text: "X" },
    ];

    // Loop through each specific URL
    specificUrls.forEach((link) => {
      // Create a regular expression to match the URL in the text
      const regex = new RegExp(link.url, "g");

      // Replace the URL in the text with the hyperlink HTML
      text = text.replace(
        regex,
        `<a href="${link.url}" target="_blank" rel="noopener noreferrer">${link.text}</a>`
      );
    });

    // Using dangerouslySetInnerHTML to render the updated text with hyperlinks
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  };

  return (
    <FAQStyleWrapper className="bithu_faq_sect" id="faq">
      <div className="container">
        <SectionTitle
          title="QUESTION & ANSWER"
          subtitle="Faq"
          className="section_title"
        />

        <div className="row faq_row">
          <div className="col-md-6">
            <div className="bithu_faq_content">
              <div className="bithu_faq_questions">
                <Accordion className="faq_questions">
                  {data1?.map((item, i) => (
                    <AccordionItem key={i}>
                      <AccordionTitle>
                        <h5>{item.title}</h5>
                        <IconWrapper>
                          <OpenIcon>
                            <FiMinus />
                          </OpenIcon>
                          <CloseIcon>
                            <FiPlus />
                          </CloseIcon>
                        </IconWrapper>
                      </AccordionTitle>
                      <AccordionBody>
                        <p>{item.text}</p>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bithu_faq_content">
              <div className="bithu_faq_questions">
                <Accordion className="faq_questions">
                  {data2?.map((item, i) => (
                    <AccordionItem key={i}>
                      <AccordionTitle onClick={() => console.log(i)}>
                        <h5>{item.title}</h5>
                        <IconWrapper>
                          <OpenIcon>
                            <FiMinus />
                          </OpenIcon>
                          <CloseIcon>
                            <FiPlus />
                          </CloseIcon>
                        </IconWrapper>
                      </AccordionTitle>
                      <AccordionBody>
                        <p>{renderTextWithSpecificLinks(item.text)}<br></br></p>
                      </AccordionBody>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FAQStyleWrapper>
  );
};

export default FAQ;
