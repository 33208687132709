import { useModal } from "../../../../utils/ModalContext";
import { Slider, SliderItem } from "../../../../common/slider/Slider";
import CoinInfoSlider from "../../coinInfoSlider";
/*import Counter from "../../../../common/counter";*/
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

/*import heartIcon from "../../../../assets/images/icon/v5-hart-icon.svg";*/
import thumb1 from "../../../../assets/images/nft/suc-kid-nobg-800.png";
import thumb2 from "../../../../assets/images/nft/suc-kid-nobg-800.png";
import thumb3 from "../../../../assets/images/nft/suc-kid-nobg-800.png";

import particle1 from "../../../../assets/images/icon/v5-thunder-icon.svg";
import particle2 from "../../../../assets/images/icon/v5-star-icon.svg";
import particle3 from "../../../../assets/images/icon/v5-coin-icon.svg";

const Banner = () => {
  const { mintModalHandle } = useModal();

  const slideImages = [thumb1, thumb2, thumb3];

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    speed: 500,
    fade: true,
    autoplaySpeed: 500,
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row banner_row">
          <div className="col-lg-6 order-1 order-lg-0">
            <div className="bithu_v5_baner_left">
              <h2>
                SUCCESS KID - $SKID <img src={particle3} alt="icon" />
              </h2>
              <div className="banner_buttons">
                <Button sm variant="mint" onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0x50a69cea809b4afed9a31a72f049a5b0b33bf5e3", "_blank")}>
                  {" "}
                  Buy Now
                </Button>
              </div>
              <div className="coin-info">
                <span>A meme of a triumphant toddler,</span>
                <span>
                now taking the crypto world by storm as a symbol of{" "}
                  <span className="highlighted"> victory in the volatile world of digital currencies.</span>
                </span>
                <span>CA: 0x50a69cEA809B4AFed9A31A72F049a5B0B33Bf5e3</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 order-0 order-lg-1">
            <div className="bithu_v5_baner_right">
              <Slider {...settings}>
                {slideImages?.map((thumb, idx) => (
                  <SliderItem key={idx}>
                    <div className="banner_nft_thumb">
                      <img src={thumb} alt="thumb" />
                    </div>
                  </SliderItem>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <CoinInfoSlider />

      {/* particles  */}
      <span className="particle_star particle_1">
        <img src={particle1} alt="icon" />
      </span>
      <span className="particle_star particle_2">
        <img src={particle2} alt="icon" />
      </span>
      <span className="particle_star particle_3">
        <img src={particle3} alt="icon" />
      </span>
      {/* particles  */}
    </BannerV1Wrapper>
  );
};

export default Banner;
