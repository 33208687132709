import { FiCheck, FiMinus } from "react-icons/fi";

const data = [
    {
        title: "Phase 02 - Building",
        features: [
            {
                icon: <FiCheck />,
                text: "CoinGecko and CoinMarketCap Listings."
            },
            {
                icon: <FiCheck />,
                text: "Small to Medium Tier Central Exchange Listings."
            },
            {
                icon: <FiCheck />,
                text: "Cultivate a vibrant and active community through social media platforms, online forums, and dedicated chat groups."
            },
            {
                icon: <FiCheck />,
                text: "Encourage community members to create and share their own funny memes related to the coin, running regular meme contests or challenges."
            },
            {
                icon: <FiCheck />,
                text: "Educate the community about the basics of cryptocurrency, blockchain technology, and the coin's unique features through humorous and easily digestible content."
            },
        ]
    },
    {
        title: "Phase 03 - Expansion",
        features: [
            {
                icon: <FiCheck />,
                text: "Seek partnerships with other meme-related projects, influencers, or brands to enhance the coin's visibility and reach a broader audience."
            },
            {
                icon: <FiCheck />,
                text: "Explore unique and humorous use cases for the coin within the meme community, such as exclusive access to meme templates, NFTs, or meme-related merchandise."
            },
            {
                icon: <FiCheck />,
                text: "Develop user-friendly wallets and platforms that integrate seamlessly with popular meme-sharing platforms or meme generators."
            },
            {
                icon: <FiCheck />,
                text: "Collaborate with meme creators, artists, or comedians to create exclusive content or special promotions for coin holders."
            },
        ]
    },
    {
        title: "Phase 04 - Growth",
        features: [
            {
                icon: <FiCheck />,
                text: "Continuously engage with the community by sharing funny updates, jokes, or memes related to the coin and the cryptocurrency space."
            },
            {
                icon: <FiCheck />,
                text: "Regularly release informative and entertaining content, such as blog posts, videos, or podcasts, about the latest trends and news in the meme and crypto world."
            },
            {
                icon: <FiCheck />,
                text: "Organize community-driven events, such as virtual meme festivals or funny meme-themed charity drives."
            },
            {
                icon: <FiCheck />,
                text: "Implement a governance model that allows coin holders to vote on future developments, new partnerships, or meme-related initiatives"
            },
        ]
    },
    {
        title: "Phase 01 - Meme Launch",
        features: [
            {
                icon: <FiCheck />,
                text: "Develop a unique and humorous meme character or concept that represents the meme coin."
            },
            {
                icon: <FiCheck />,
                text: "Design a catchy and visually appealing token logo and branding materials."
            },            {
                icon: <FiCheck />,
                text: "Launch the token through a fun and engaging event, such as a meme contest or viral campaign."
            },            {
                icon: <FiCheck />,
                text: "Establish a dedicated website and social media presence that reflects the coin's humor and provides basic information about the project."
            },
        ]
    },
]

export default data;