import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/ab-img2.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  aboutDescription1:
    "$SKID is tired of watching everyone play hot potato with the endless derivative ShibaCumGM ElonKishu TurboAss FlokiMoon Inu coins. The Inu’s have had their day. It’s time for the most recognizable meme in the world to take his reign as king of the memes.",
  aboutDescription2:
    '$SKID is here to make memecoins great again. Launched stealth with no presale, zero taxes, LP burnt and contract renounced, $SKID is a coin for the people, forever. Fueled by pure memetic power, let $SKID show you the way.',
  artists: [
    {
      thumb: aboutThumb1,
      icon: aboutIcon,
      name: "Berneice Tran",
      role: "Artist",
    },
    {
      thumb: aboutThumb2,
      icon: aboutIcon,
      name: "Leana Rosa",
      role: "Co-Artist",
    },
  ],
};

export default data;
