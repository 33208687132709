import nftImg1 from "../images/nft/cat1.png";
import nftImg2 from "../images/nft/cat2.png";
import nftImg3 from "../images/nft/cat3.png";
import nftImg4 from "../images/nft/cat4.png";
import nftImg5 from "../images/nft/cat5.png";
import nftImg6 from "../images/nft/cat6.png";
import nftImg7 from "../images/nft/cat7.png";
import nftImg8 from "../images/nft/cat8.png";
import nftImg9 from "../images/nft/cat9.png";
import nftImg10 from "../images/nft/cat10.png";
import nftImg11 from "../images/nft/cat11.png";
import nftImg12 from "../images/nft/cat12.png";

const data = [
  nftImg1,
  nftImg2,
  nftImg3,
  nftImg4,
  nftImg5,
  nftImg6,
  nftImg7,
  nftImg8,
  nftImg9,
  nftImg10,
  nftImg11,
  nftImg12,
];

export default data;
