import aboutThumb1 from "../../images/blog/ab-img.png";
import aboutThumb2 from "../../images/blog/ab-img2.png";
import aboutIcon from "../../images/icon/ab-icon-image.png";

const data = {
  TeamDescription1:
    "$SKID is comes with NO TAX. 100% of the tokens were sent to the liquidity pool, LP tokens Locked forever, and contract is renounced.",
  TeamDescription2:
    '$SKID is here to make memecoins great again. Launched stealth with no presale, zero taxes, LP burnt and contract renounced, $SKID is a coin for the people, forever. Fueled by pure memetic power, let $SKID show you the way.',
};

export default data;
