export const data1 = [
  {
    title: "What is the token contract address?",
    text: "0x50a69cEA809B4AFed9A31A72F049a5B0B33Bf5e3",
  },
  {
    title: "What is the token's total supply and distribution model?",
    text: "Total Supply of 88,888,888. Tax: 0/0. Liquidity is locked for 100 years",
  },
  {
    title: "Are there any token utility or staking mechanisms?",
    text: "As a memecoin, the utility of the token can revolve around its use as a meme. As the project grows, more utilities will be announced.",
  },
];
export const data2 = [
  {
    title: "Where can I find more information or engage with the community??",
    text: "https://linktr.ee/successkid",
  },
  {
    title: "How can I buy the token on Uniswap?",
    text: "Set up an Ethereum wallet (e.g., MetaMask) and ensure it is funded with Ethereum (ETH) and Connect to Uniswap. Search for the token you want to buy by entering its name or contract address. Select the trading pair and enter the desired amount. Review the transaction details and approve it in your Ethereum wallet. Once the transaction is processed, the tokens will be transferred to your wallet.",
  },
  
];
